import { useEffect, useMemo, useRef, useState, useTransition } from 'react'
import { useTranslation } from 'react-i18next'
import Tippy from '@tippyjs/react'
import { addDays } from 'date-fns'
import { addMonths } from 'date-fns/addMonths'
import { differenceInCalendarDays } from 'date-fns/differenceInCalendarDays'
import { fromZonedTime } from 'date-fns-tz'
import { AnimatePresence, motion } from 'framer-motion'
import groupBy from 'lodash/groupBy'
import { rgba } from 'polished'
import styled from 'styled-components'

import {
  BookingBar,
  MobilePosition,
  PanelPosition,
} from 'bl-common/src/booking/BookingBar/BookingBar'
import { BookingBarButton } from 'bl-common/src/booking/BookingBar/styles'
import { Disclaimer } from 'bl-common/src/booking/Disclaimer/Disclaimer'
import { Picker as PickerBase } from 'bl-common/src/booking/Picker/Picker'
import { colors } from 'bl-common/src/constants/colors'
import { base, modularScale } from 'bl-common/src/constants/sizes'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { InlineDatePicker } from 'bl-common/src/form/InlineDatePicker/InlineDatePicker'
import { IPricesFields } from 'bl-common/src/generated/contentful'
import { useBreakpoints } from 'bl-common/src/hooks/useBreakpoints'
import { theme } from 'bl-common/src/styles/theme'
import { PartialBookingEngine } from 'bl-common/src/styles/types'
import { between } from 'bl-common/src/utils/between'
import { mediaObj } from 'bl-common/src/utils/media'
import { Property, useRoomRangeAvailabilityLazyQuery } from 'bl-graphql'
import { getValidStayDates, startOfCurrentUTCDate } from 'bl-utils/src/date'
import { formatDateInUTC } from 'bl-utils/src/formatting/formatDate'

import {
  calculateNumberOfGuests,
  isDateAvailableAsCheckout,
  isDateCheckoutOnly,
  isDateIncludedInArray,
  isSelectingEndDate,
  isValidDateRange,
} from '../../../utils/helpers'
import { INITIAL_ROOM_SELECTION, MAX_ROOMS } from '../constants'
import { RoomSelection } from '../types'

export interface IHotelBookingBarState {
  rooms: RoomSelection
  dates: Date[]
  property: Property
}

interface HotelBookingBarProps {
  panelPosition?: PanelPosition
  onApply?: (state: IHotelBookingBarState) => void
  onChange?: (state: Partial<IHotelBookingBarState>) => void
  ctaLabel?: string
  price?: IPricesFields
  state?: IHotelBookingBarState
  roomType?: string
  mobilePosition?: MobilePosition
  showPropertySelector?: boolean
  maxDate?: Date
  showGuestPicker?: boolean
  // if switchBetweenPanels is true, the bottom button in mobile view panel
  // goes to next step instead of closing the drawer
  switchBetweenPanels?: boolean
  mobileBarAsButton?: boolean
  themeStyle?: PartialBookingEngine
}

// #region Styles
const DatePickerContainer = styled.div({
  background: colors.white,
  paddingLeft: theme.spacing[2],
  paddingRight: theme.spacing[2],
  [mediaObj.md]: {
    paddingLeft: 'unset',
    paddingRight: 'unset',
  },
})

const Picker = styled(PickerBase)({
  marginBottom: theme.spacing[1],
})

const PickerContainer = styled.div({
  paddingLeft: theme.spacing[2],
  paddingRight: theme.spacing[2],
  [mediaObj.md]: {
    height: 'auto',
    paddingLeft: 'unset',
    paddingRight: 'unset',
  },
})

const MobileOnlyType = styled(Type)({
  display: 'block',
  [mediaObj.md]: {
    display: 'none',
  },
})

const DayWrapper = styled.span({
  display: 'inline-block',
  width: '100%',
  height: '100%',
  pointerEvents: 'all',
})

const CustomSelect = styled.div({
  position: 'relative',
})

const RoomContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  position: 'sticky',

  [mediaObj.md]: {
    top: -25,
    margin: -24,
    padding: 24,
    paddingTop: 16,
    paddingBottom: 16,
    marginBottom: 4,

    borderBottom: `1px solid ${rgba(colors.midGrey, 0.15)}`,
    zIndex: 2,
  },

  background: 'white',
})

const RoomHeading = styled(Type)({
  fontSize: '16px',
  color: colors.deepBlue,
  textTransform: 'capitalize',
})

const RoomPicker = styled.div<{ selected: boolean }>(({ selected }) => ({
  display: 'flex',
  height: '30px',
  minWidth: '30px',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  border: selected
    ? `2px solid ${colors.deepBlue}`
    : '1px solid rgba(191, 191, 191, 0.5)',
}))

const HotelItem = styled.li<{ selected: boolean }>(({ selected }) => ({
  cursor: 'pointer',
  color: selected ? colors.white : colors.grey,
  backgroundColor: selected ? colors.deepBlue : colors.white,
  padding: 24,
  width: '100%',

  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
  columnGap: theme.spacing[0.5],
  '&:hover': {
    color: colors.white,
    // this color is not in colors because it's only used here so far
    backgroundColor: '#34717F',
    [Stars]: {
      color: colors.white,
    },
  },
  p: {
    minWidth: 96,
    paddingTop: 4,
  },
}))

const Stars = styled.div<{ selected?: boolean }>(({ selected }) => ({
  display: 'flex',
  columnGap: 1,
  color: selected ? colors.white : colors.deepBlue,
  svg: {
    height: theme.spacing[1],
    width: theme.spacing[1],
    [mediaObj.md]: {
      height: between((base / modularScale) * 0.75, base * 0.75),
      width: between((base / modularScale) * 0.75, base * 0.75),
    },
  },
}))

const MailLink = styled.a({
  color: colors.deepBlue,
  fontWeight: 600,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
})

const RoomPickerList = styled.ul({
  listStyle: 'none',
  display: 'flex',
  gap: '8px',
})

const RoomList = styled.ul({
  marginTop: theme.spacing[2],
  [mediaObj.lg]: {
    marginTop: theme.spacing[1],
  },
})

const Room = styled.li({
  marginBottom: theme.spacing[3],

  ':last-child': {
    marginBottom: theme.spacing[1],
  },

  [mediaObj.lg]: {
    marginBottom: theme.spacing[2],
  },
  [mediaObj.xl]: {
    marginBottom: theme.spacing[1.5],
  },
})

const DisclaimerWrapper = styled.div({
  position: 'sticky',
  bottom: -20,

  paddingTop: theme.spacing[1],
  paddingBottom: theme.spacing[1],
  backgroundColor: colors.white,

  [mediaObj.md]: {
    position: 'inherit',
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: 'none',

    //using percentage to try to match the calendar
    paddingLeft: '2.3%',
  },
})

const StarFilled = () => (
  <svg
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12.91 12.28"
  >
    <path
      fill="currentColor"
      d="m6.46 0 1.99 4.04 4.46.65-3.23 3.15.77 4.44-3.99-2.1-3.99 2.1.76-4.44L0 4.69l4.46-.65 2-4.04z"
      data-name="Layer 1"
    />
  </svg>
)

const StarOutline = () => (
  <svg
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12.91 12.28"
  >
    <path
      d="m6.46 2.26 1.1 2.23.23.47.52.08 2.46.36-1.78 1.73-.38.37.09.52.42 2.45-2.2-1.15-.47-.24-.47.24-2.2 1.15.42-2.45.09-.52-.38-.37L2.13 5.4l2.46-.36.52-.08.23-.47 1.1-2.23m0-2.26L4.45 4.04l-4.46.65 3.23 3.15-.76 4.44 3.99-2.1 3.99 2.1-.76-4.44 3.23-3.15-4.46-.65L6.46 0Z"
      fill="currentColor"
      data-name="Layer 1"
    />
  </svg>
)
// #endregion

export const HotelBookingBar = ({
  panelPosition,
  onApply,
  onChange,
  ctaLabel = 'Book now',
  price,
  state,
  roomType,
  mobilePosition = MobilePosition.FixedToBottom,
  showPropertySelector = false,
  maxDate,
  showGuestPicker = true,
  switchBetweenPanels = false,
  mobileBarAsButton = false,
  themeStyle,
}: HotelBookingBarProps) => {
  const containerRef = useRef(null)
  const barRef = useRef(null)
  const { t, i18n } = useTranslation('booking')
  const refLastBlockedDay = useRef<Date>(null)
  const rooms = state?.rooms || INITIAL_ROOM_SELECTION
  // This .toUppercase() should be redundant becase state is typed correctly
  const property = state?.property?.toUpperCase() as Property

  const [warning, setWarning] = useState<string | undefined>(undefined)
  const [disclaimer, setDisclaimer] = useState<string | undefined>(undefined)
  const [info, setInfo] = useState<string | undefined>(undefined)

  const [roomDisclaimers, setRoomDisclaimers] =
    useState<('children' | 'infants' | 'adults' | null)[]>(null)

  const [, startDateChangeTransition] = useTransition()

  const { isMobile, mediaBmd, mediaMd, mediaLg } = useBreakpoints()
  const [fetchRoomRangeAvailability, { data }] =
    useRoomRangeAvailabilityLazyQuery()

  const today = startOfCurrentUTCDate()

  // initialize roomDisclaimers
  useEffect(() => {
    const disclaimers = []
    for (let index = 0; index < MAX_ROOMS; index++) {
      disclaimers.push(null)
    }
    setRoomDisclaimers(disclaimers)
  }, [])

  const handleSetDisclaimer = (index, disclaimer) => {
    const disclaimers = roomDisclaimers
    disclaimers[index] = disclaimer
    setRoomDisclaimers([...disclaimers])

    // removing the disclaimer again after a few seconds
    setTimeout(() => {
      const disclaimers = roomDisclaimers
      disclaimers[index] = null
      setRoomDisclaimers([...disclaimers])
    }, 4000)
  }

  useEffect(() => {
    const isRetreat = property === Property.Retreat
    const nrOfNights = differenceInCalendarDays(state.dates[1], state.dates[0])
    if (isRetreat && (nrOfNights < 2 || !state.dates[1])) {
      setDisclaimer(t('booking.bar.minlos.disclaimer'))
    } else {
      setDisclaimer(undefined)
    }
  }, [state.dates, property])

  useEffect(() => {
    const input = {
      from: formatDateInUTC(today, 'yyyy-MM-dd'),
      to: formatDateInUTC(addMonths(today, 12), 'yyyy-MM-dd'),
      availableOnly: false,
      enforceRestrictions: true,
      ...(roomType && { roomType }),
      ...(state.property !== undefined && { property: state.property }),
    }

    fetchRoomRangeAvailability({
      variables: {
        input,
      },
    })
  }, [state.property, roomType])

  useEffect(() => {
    // This will check if the dates are in the past, then return new dates or just an empty array if they are valid
    const newDates = getValidStayDates(state.dates[0], state.dates[1])
    if (newDates.length === 2) {
      onChange({ dates: newDates })
    }
  }, [])

  const [startDate, endDate] = state.dates || []

  const onBookClicked = () => {
    // If end date is not specified, set the next day as the end date
    // Many users were just selecting start date in mobile and thought it would be one night.
    if (!endDate) {
      const nextDay = addDays(new Date(startDate), 1)

      onChange({ dates: [startDate, nextDay] })
      onApply?.({ ...state, dates: [startDate, nextDay] })
    } else {
      onApply?.(state)
    }
    setInfo(undefined)
  }

  const lastAvailableDate = useMemo(() => {
    let lastAvailableDate = today.toISOString()
    data?.roomRangeAvailability.forEach(room => {
      if (lastAvailableDate < room.date) {
        lastAvailableDate = room.date
      }
    })
    return new Date(lastAvailableDate)
  }, [data])

  const BlockedDay = ({ day, message }: { day: number; message: string }) => (
    <DayWrapper
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
        setWarning(t(message))
      }}
    >
      {day}
    </DayWrapper>
  )

  const shouldBlockDay = (date: Date) => {
    // If a blocked day has already been set, and this date is later, update the blocked day and return true.
    if (refLastBlockedDay.current && date > refLastBlockedDay.current) {
      return true
    }

    // Otherwise check if the date is unavailable as a checkout day.
    // Please note that isDateAvailableAsCheckout is a very expensive function so we would like to avoid calling it if possible.
    if (!isDateAvailableAsCheckout(date, excludedDates, startDate)) {
      refLastBlockedDay.current = date
      return true
    }
  }

  const excludedDates: Date[] = useMemo(() => {
    const availabilityGroupedByDate = groupBy(
      data?.roomRangeAvailability,
      'date'
    )
    return Object.keys(availabilityGroupedByDate).reduce((arr, key) => {
      const date = availabilityGroupedByDate[key]
      const hasAvailability = date.some(
        room => room.availableRooms >= state.rooms.length
      )

      if (!hasAvailability) {
        arr.push(new Date(key))
      }
      return arr
    }, [])
  }, [data])

  const excludedArrivalDates: Date[] = useMemo(() => {
    const availabilityGroupedByDate = groupBy(
      data?.roomRangeAvailability,
      'date'
    )
    return Object.keys(availabilityGroupedByDate).reduce((arr, key) => {
      const date = availabilityGroupedByDate[key]
      const hasArrivalAvailability = date.some(room => room.validArrivalDate)

      if (!hasArrivalAvailability) {
        arr.push(new Date(key))
      }
      return arr
    }, [])
  }, [data])

  const onRoomChanged = (roomIndex: number, value) => {
    const newRoom = { ...rooms[roomIndex], ...value }

    // if children count is changed and it is higher than allowed, remove offer from selection
    if (
      newRoom.offer &&
      value.children &&
      value.children > newRoom.offer.allowedChildren
    ) {
      newRoom.offer = undefined
    }

    // if infants count is changed and it is higher than allowed, remove offer from selection
    if (
      newRoom.offer &&
      value.infants &&
      value.infants > newRoom.offer.allowedInfants
    ) {
      newRoom.offer = undefined
    }

    onChange({
      rooms: [
        ...rooms.slice(0, roomIndex),
        newRoom,
        ...rooms.slice(roomIndex + 1),
      ],
    })
  }

  const guestCount = calculateNumberOfGuests(rooms)

  const pluralizedRooms =
    rooms.length > 1
      ? t('booking.general.room_plural')
      : t('booking.general.room')
  const pluralizedGuests =
    guestCount > 1
      ? t('booking.general.guest_plural')
      : t('booking.general.guest')

  return (
    <BookingBar
      ref={barRef}
      panelPosition={panelPosition}
      button={
        <BookingBarButton
          onClick={onBookClicked}
          preset={themeStyle?.hotelBookingBar?.button?.preset}
          type="button"
        >
          {ctaLabel}
        </BookingBarButton>
      }
      price={price}
      mobilePosition={mobilePosition}
      columnTemplate={
        mediaBmd
          ? `${showPropertySelector ? 'minmax(150px, 1fr)' : ''} ${
              showGuestPicker ? 'minmax(270px, 1fr)' : ''
            }  minmax(150px, 1fr) minmax(150px, 1fr) 227px`
          : mediaMd
            ? `${showPropertySelector ? 'minmax(140px, 1fr)' : ''} ${
                showGuestPicker ? 'minmax(180px, 1fr)' : ''
              }  minmax(130px, 1fr) minmax(130px, 1fr) minmax(140px, 227px)`
            : `${showPropertySelector ? 'minmax(150px, 1fr)' : ''} ${
                showGuestPicker ? 'minmax(270px, 1fr)' : ''
              }  minmax(150px, 1fr) minmax(150px, 1fr) minmax(140px, 227px)`
      }
      switchBetweenPanels={switchBetweenPanels}
      mobileBarAsButton={mobileBarAsButton}
    >
      {showPropertySelector && (
        <BookingBar.Item
          id="hotel"
          label={t('booking.bar.hotelPicker.label')}
          value={t(state.property)}
          panelId="hotelPicker"
          themeColor={themeStyle?.hotelBookingBar?.input?.label?.color}
        />
      )}
      {showGuestPicker && (
        <BookingBar.Item
          id="guests"
          label={t('booking.bar.guests.and.rooms.label')}
          value={`${guestCount} ${pluralizedGuests}, ${rooms?.length} ${pluralizedRooms}`}
          mobileValue={`${rooms?.length} ${pluralizedRooms}: ${guestCount} ${pluralizedGuests}`}
          panelId="guestPicker"
          themeColor={themeStyle?.hotelBookingBar?.input?.label?.color}
        />
      )}

      <BookingBar.Item
        id="arrivalDate"
        label={t('booking.bar.checkIn.label')}
        value={
          startDate
            ? formatDateInUTC(startDate, 'd MMMM', i18n.language)
            : 'Select'
        }
        mobileValue={
          startDate
            ? `${formatDateInUTC(
                startDate,
                'd MMM',
                i18n.language
              )}-${formatDateInUTC(endDate, 'd MMM', i18n.language)}`
            : 'Select'
        }
        panelId="datePicker"
        themeColor={themeStyle?.hotelBookingBar?.input?.label?.color}
      />
      <BookingBar.Item
        id="departureDate"
        label={t('booking.bar.checkOut.label')}
        value={
          endDate ? formatDateInUTC(endDate, 'd MMMM', i18n.language) : 'Select'
        }
        panelId="datePicker"
        themeColor={themeStyle?.hotelBookingBar?.input?.label?.color}
      />
      {showPropertySelector && (
        <BookingBar.Panel
          id="hotelPicker"
          span={{ md: 2, mlg: 1 }}
          style={{ padding: 0 }}
        >
          <PickerContainer>
            <MobileOnlyType preset="headlineLarge" bottom={{ xs: 2 }}>
              {t('booking.hotel.hotelpicker.headline')}
            </MobileOnlyType>
            <CustomSelect>
              <ul>
                <HotelItem
                  value={Property.Silica}
                  onClick={() => {
                    onChange({ property: Property.Silica })
                  }}
                  selected={property === Property.Silica}
                >
                  <p>{t(Property.Silica)}</p>
                  <Stars selected={property === Property.Silica}>
                    <StarFilled />
                    <StarFilled />
                    <StarFilled />
                    <StarFilled />
                    <StarOutline />
                  </Stars>
                </HotelItem>
                <HotelItem
                  value={Property.Retreat}
                  onClick={() => {
                    onChange({ property: Property.Retreat })
                  }}
                  selected={property === Property.Retreat}
                >
                  <p>{t(Property.Retreat)}</p>
                  <Stars selected={property === Property.Retreat}>
                    <StarFilled />
                    <StarFilled />
                    <StarFilled />
                    <StarFilled />
                    <StarFilled />
                  </Stars>
                </HotelItem>
              </ul>
            </CustomSelect>
          </PickerContainer>
        </BookingBar.Panel>
      )}

      {showGuestPicker && (
        <BookingBar.Panel
          id="guestPicker"
          span={{ md: 2, mlg: 1 }}
          style={{
            maxHeight: !isMobile && '50vh',
            overflow: !isMobile && 'scroll',
            color: colors.dark,
          }}
        >
          <PickerContainer>
            <MobileOnlyType
              preset="headlineLarge"
              bottom={{ xs: 2 }}
              size={{ xs: 16 }}
            >
              {t('booking.hotel.guestpicker.headline')}
            </MobileOnlyType>
            <RoomContainer>
              <Type
                preset="label"
                style={{ marginRight: '20px', paddingTop: 3, fontSize: '14px' }}
              >
                {t('booking.general.noOfRooms')}
              </Type>
              <RoomPickerList>
                {Array.from(Array(MAX_ROOMS).keys()).map(index => (
                  <RoomPicker
                    key={index}
                    selected={rooms.length === index + 1}
                    onClick={() => {
                      // add room if index is higher than current room count
                      if (index + 1 > rooms.length) {
                        const diff = index + 1 - rooms.length
                        onChange({
                          rooms: [
                            ...rooms,
                            ...Array(diff).fill(INITIAL_ROOM_SELECTION[0]),
                          ],
                        })
                      }
                      // remove room if index is lower than current room count
                      if (index + 1 < rooms.length) {
                        onChange({
                          rooms: rooms.slice(0, index + 1),
                        })
                      }
                    }}
                  >
                    <Type
                      style={{
                        marginTop: '4px',
                        fontSize: '16px',
                      }}
                    >
                      {index + 1}
                    </Type>
                  </RoomPicker>
                ))}
              </RoomPickerList>
            </RoomContainer>
            <Type style={{ fontSize: '12px', whiteSpace: 'nowrap' }} mTop={1}>
              {t('booking.hotel.more.rooms')}
              <MailLink
                href={
                  state.property === Property.Silica
                    ? 'mailto:silicahotel@bluelagoon.is'
                    : 'mailto:retreathotel@bluelagoon.is'
                }
                target="_blank"
                rel="noreferrer"
              >
                {state.property === Property.Silica
                  ? 'silicahotel@bluelagoon.is'
                  : 'retreathotel@bluelagoon.is'}
              </MailLink>
            </Type>
            <RoomList>
              {rooms.map((room, index) => (
                <Room key={index}>
                  <RoomHeading weight="bold" mBottom={mediaLg ? 0.5 : 1}>{`${t(
                    'booking.general.room'
                  )} ${index + 1}`}</RoomHeading>
                  {property === Property.Silica ? (
                    <>
                      <Picker
                        min={1}
                        max={2}
                        text={t('booking.general.adult_plural')}
                        value={room.adults}
                        onChange={(value: number) => {
                          onRoomChanged(index, { adults: value })
                        }}
                        rounded
                        fixedSize
                        onDisabledIncrementClick={() =>
                          handleSetDisclaimer(index, 'adults')
                        }
                      />
                      <Picker
                        max={2}
                        value={room.children}
                        text={`${t('booking.general.child_plural')} 3-10 ${t(
                          'booking.general.year_plural'
                        )}`}
                        disableIncrement={room.children >= 2}
                        onChange={(value: number) => {
                          onRoomChanged(index, { children: value })
                        }}
                        rounded
                        fixedSize
                        onDisabledIncrementClick={() =>
                          handleSetDisclaimer(index, 'children')
                        }
                      />
                      <Picker
                        max={2}
                        value={room.infants}
                        text={`${t('booking.general.infant_plural')} 0-2 ${t(
                          'booking.general.year_plural'
                        )}`}
                        disableIncrement={room.infants >= 1}
                        onChange={(value: number) => {
                          onRoomChanged(index, { infants: value })
                        }}
                        rounded
                        fixedSize
                        onDisabledIncrementClick={() =>
                          handleSetDisclaimer(index, 'infants')
                        }
                      />
                    </>
                  ) : (
                    <Picker
                      min={1}
                      max={2}
                      text={t('booking.general.guest_plural')}
                      value={rooms[index].adults}
                      onChange={(value: number) => {
                        onRoomChanged(index, { adults: value })
                      }}
                      rounded
                      fixedSize
                      onDisabledIncrementClick={() =>
                        handleSetDisclaimer(index, 'adults')
                      }
                    />
                  )}
                  <AnimatePresence>
                    {roomDisclaimers?.[index] !== null && (
                      <>
                        {roomDisclaimers?.[index] === 'adults' && (
                          <motion.div
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0 }}
                          >
                            <Disclaimer
                              color={colors.errorRed}
                              preset="textSmall"
                              fixedFontSize={16}
                            >
                              {t('booking.general.adultsDisclaimer')}
                            </Disclaimer>
                          </motion.div>
                        )}
                        {roomDisclaimers?.[index] === 'children' && (
                          <motion.div
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0 }}
                          >
                            <Disclaimer
                              color={colors.errorRed}
                              preset="textSmall"
                              fixedFontSize={16}
                            >
                              {t('booking.general.childrenDisclaimer')}
                            </Disclaimer>
                          </motion.div>
                        )}
                        {roomDisclaimers?.[index] === 'infants' && (
                          <motion.div
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0 }}
                          >
                            <Disclaimer
                              color={colors.errorRed}
                              preset="textSmall"
                              fixedFontSize={16}
                            >
                              {t('booking.general.infantsDisclaimer')}
                            </Disclaimer>
                          </motion.div>
                        )}
                      </>
                    )}
                  </AnimatePresence>
                </Room>
              ))}
            </RoomList>
          </PickerContainer>
        </BookingBar.Panel>
      )}

      <BookingBar.Panel
        id="datePicker"
        span={{
          md: 5,
          mlg: !showGuestPicker && !showPropertySelector ? 2 : 4,
          lg: !showGuestPicker && !showPropertySelector ? 2 : 3,
        }}
      >
        <DatePickerContainer ref={containerRef}>
          <MobileOnlyType preset="headlineLarge" bottom={{ xs: 0.5 }}>
            {t('booking.hotel.datepicker.headline')}
          </MobileOnlyType>
          <MobileOnlyType preset="text" bottom={{ xs: 1 }}>
            {t('booking.hotel.datepicker.subheadline')}
          </MobileOnlyType>
          <InlineDatePicker
            startDate={startDate}
            endDate={endDate}
            onChange={dates => {
              startDateChangeTransition(() => {
                setWarning(undefined)
                onChange({ dates })
                refLastBlockedDay.current = null

                const [newStartDate, newEndDate] = dates || []

                if (isSelectingEndDate(newStartDate, newEndDate)) {
                  barRef.current?.focusIndex(3)
                  setInfo(t('booking.bar.select.departure.date'))
                } else {
                  barRef.current?.focusIndex(2)
                  setInfo(undefined)

                  if (
                    !isValidDateRange(newStartDate, newEndDate, excludedDates)
                  ) {
                    setWarning(t('booking.bar.unavailable.dates.warning'))
                  } else if (!isMobile) {
                    setTimeout(() => {
                      barRef.current?.onClose()
                    }, 500)
                  }
                }
              })
            }}
            selectsRange
            monthsShown={isMobile ? 6 : 2}
            loadMonthsOnScroll={isMobile}
            openToDate={isMobile ? today : undefined}
            minDate={new Date(today)}
            maxDate={maxDate || lastAvailableDate}
            themeStyle={themeStyle?.inlineDatePicker}
            dayClassName={date => {
              let className = undefined
              const dateInUTC = fromZonedTime(date, 'UTC')

              if (
                dateInUTC > startDate &&
                isSelectingEndDate(startDate, endDate)
              ) {
                // Special checks when we are selecting departure date
                if (shouldBlockDay(dateInUTC)) {
                  className = 'react-datepicker__day--blocked'
                }
              } else {
                // Special checks when we are selecting arrival date
                if (isDateCheckoutOnly(dateInUTC, excludedDates)) {
                  className = 'react-datepicker__day--checkout-only'
                } else if (
                  isDateIncludedInArray(dateInUTC, excludedDates) ||
                  isDateIncludedInArray(dateInUTC, excludedArrivalDates)
                ) {
                  className = 'react-datepicker__day--blocked'
                }
              }
              return className
            }}
            renderDayContents={(day, date) => {
              const [startDate, endDate] = state.dates || []
              const dateInUTC = fromZonedTime(date, 'UTC')

              if (
                dateInUTC > startDate &&
                isSelectingEndDate(startDate, endDate)
              ) {
                if (shouldBlockDay(dateInUTC)) {
                  return (
                    <BlockedDay
                      day={day}
                      message={'booking.bar.unavailable.dates.warning'}
                    />
                  )
                }
              } else {
                if (isDateCheckoutOnly(dateInUTC, excludedDates)) {
                  return (
                    <Tippy
                      content={t('booking.bar.checkout.only.warning')}
                      placement="top"
                    >
                      <BlockedDay
                        day={day}
                        message={'booking.bar.checkout.only.warning'}
                      />
                    </Tippy>
                  )
                } else if (isDateIncludedInArray(dateInUTC, excludedDates)) {
                  return (
                    <BlockedDay
                      day={day}
                      message={'booking.bar.fullyBooked.warning'}
                    />
                  )
                }
              }

              return <DayWrapper>{day}</DayWrapper>
            }}
          />
          <DisclaimerWrapper
            style={{
              minHeight:
                warning && disclaimer ? theme.spacing[3.5] : theme.spacing[1.5],
            }}
          >
            {!!warning && (
              <Disclaimer
                weight="normal"
                color={colors.errorRed}
                mBottom={0.5}
                fixedFontSize={16}
              >
                {warning}
              </Disclaimer>
            )}
            {!!disclaimer && (
              <Disclaimer weight="normal" fixedFontSize={16}>
                {disclaimer}
              </Disclaimer>
            )}
            {!!info && (
              <Disclaimer weight="normal" fixedFontSize={16}>
                {info}
              </Disclaimer>
            )}
          </DisclaimerWrapper>
        </DatePickerContainer>
      </BookingBar.Panel>
    </BookingBar>
  )
}

export default HotelBookingBar
